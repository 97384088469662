import React, { ChangeEvent, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ActionMeta, OptionsType } from 'react-select';
import { AccessToken } from '@okta/okta-auth-js';
import Swal from 'sweetalert2';
import Calendar from '../../components/Calendar/Calendar';
import LabelInputLabel from '../../components/LabelInputLabel/LabelInputLabel';
import Dropdown, { DropObject } from '../../components/Dropdown/Dropdown';
import MultiDropdown from '../../components/MultiDropdown/MultiDropdown';
import TextInput from '../../components/TextInput/TextInput';
import ProgressStepBar from '../../components/ProgressStepBar/ProgressStepBar';
import Sidebar from '../../components/Sidebar/Sidebar';
import './Input.scss';
import getEnv from '../../envs';
import {
	subjects,
	products,
	grades,
	galileoItemTypes,
	catItemTypes,
	status,
	delimiters,
	responseTypes,
} from '../../constants/data';

function Input() {
	const { authState, oktaAuth } = useOktaAuth();
	const [accessToken, setAccessToken] = useState<AccessToken | undefined>(undefined);

	useEffect(() => {
		if (authState === null || !authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setAccessToken(undefined);
		} else {
			setAccessToken(authState.accessToken);
		}
	}, [accessToken, authState, oktaAuth]); // eslint-disable-line react-hooks/exhaustive-deps
	// Update if authState changes

	let statusInterval: NodeJS.Timeout;
	let statusGettingDataText: string = '';
	let statusUploadingDataText: string = '';
	let statusCompleteText: string = '';
	let statusErrorText: string = '';

	const buttonDataClicked = () => {
		const studentAssessmentGrades = GetStudentAssessGrades();
		const studentChronologicalGrades = GetStudentChronGrades();
		const itemGrades = GetItemGrades();
		const iTypes = GetItemTypes();
		const statuses = GetStatuses();
		const rTypes = GetResponseTypes();

		setStatusCode(-1);
		setStatusMessageGettingData('');
		setStatusMessageUploadingData('');
		setStatusMessageComplete('');
		setStatusMessageError('');
		statusGettingDataText = '';
		statusUploadingDataText = '';
		statusCompleteText = '';
		statusErrorText = '';

		if (
			studentAssessmentGrades === '' ||
			studentChronologicalGrades === '' ||
			itemGrades === '' ||
			iTypes === '' ||
			statuses === '' ||
			selectedSubj.label === '' ||
			selectedProd.label === '' ||
			timeTaken === '' ||
			minItemCount === '' ||
			rTypes === ''
		) {
			Swal.fire({
				title: 'Error!',
				text: 'All necessary settings have not been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		if (startDate > endDate) {
			Swal.fire({
				title: 'Error!',
				text: 'The start date is set after the end date.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		const obj = {
			product: `${selectedProd.value}`,
			subject: `${selectedSubj.label}`.toLowerCase(),
			studentChronologicalGrades:
				studentChronologicalGrades === 'all' ? '' : studentChronologicalGrades,
			studentAssessmentGrades: studentAssessmentGrades === 'all' ? '' : studentAssessmentGrades,
			itemGrades: itemGrades === 'all' ? '' : itemGrades,
			itemTypes: iTypes,
			dateRangeStart: startDate.toLocaleDateString(),
			dateRangeEnd: endDate.toLocaleDateString(),
			assessmentStatus: rTypes,
			minimumMinutesSpentOnAssessment: parseInt(`${timeTaken}`, 10),
			minimumItemUseCount: parseInt(`${minItemCount}`, 10),
			outputDelimiter: `${selectedDelimiter.value}`,
			itemStatus: statuses,
		};

		let token = '';
		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				token = `Bearer ${authState.accessToken.accessToken}`;
			}
		}

		const requestID = new Date().getTime();

		const getDataUrl = `${getEnv().itemCalibrationBaseURL}retrieveData/${requestID}`;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus(token, requestID), 2000);

		if (token !== '') {
			setShowModal(true);
			fetch(getDataUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
				body: JSON.stringify(obj),
			})
				.then((response) => response.text())
				.then((jsonStatus) => {
					// do a try catch here to find out if we're getting a response with json in it, or one without
					// if it doesn't have JSON, it's a server error of some kind
					try {
						const result = JSON.parse(jsonStatus);
						setStatusCode(result.statusCode);
						WriteStatusInfo(result.statusCode, result, result.message);
					} catch (e) {
						setTimeout(() => {
							setStatusCode(99);
							// this would be a server error
							// if it has multiple lines, split it up to get the first line of it
							const statusText = () => {
								return jsonStatus?.split('\n') ? jsonStatus?.split('\n')[0] : jsonStatus;
							};
							WriteStatusInfo(99, '', statusText());
						}, 3000);
					}
				})
				.catch((err) => {
					clearInterval(statusInterval);
					setTimeout(() => {
						setStatusCode(99);
						WriteStatusInfo(99, err, err.message);
					}, 3000);
				});
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	async function CheckStatus(token: string, requestID: number) {
		let code = -1;
		let statusToDisplay = '';
		let statusMessage = '';

		// if the statusCode is 99, it means an error has been detected already and the API should not be called again
		if (statusCode === 99) {
			code = 99;
		} else {
			const getStatusUrl = `${getEnv().itemCalibrationBaseURL}inputStatus/${requestID}`;

			const response = await fetch(getStatusUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				// do a try catch here to find out if we're getting a response with json in it, or one without
				// if it doesn't have JSON, it's a server error of some kind
				const text = await response.text();
				const statusData = JSON.parse(text);
				statusToDisplay = statusData;
				statusMessage = statusData.message;
				code = statusData.statusCode;
			} catch (e) {
				statusMessage = response.message;
				code = 99;
			}
			setStatusCode(code);
		}

		WriteStatusInfo(code, statusToDisplay, statusMessage);

		if (code >= 6) {
			clearInterval(statusInterval);
		}
	}

	function WriteStatusInfo(code: number, statusToDisplay: string, statusMessage: string) {
		statusErrorText = '';
		switch (code) {
			case -1:
				statusErrorText = 'No status could be found.';
				break;
			case 0:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 1:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 2:
				// skipping for now
				// statusGettingDataText = 'Received Data from Snowflake.';
				break;
			case 3:
				// skipping for now
				// statusGettingDataText = 'Writing files.';
				break;
			case 4:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 5:
				// skipping for now
				// statusUploadingDataText = 'Files are uploaded.';
				break;
			case 6:
				statusCompleteText = JSON.stringify(statusToDisplay);
				break;
			case 7:
				statusErrorText = 'No data could be found for the filter selections.';
				break;
			case 99:
				statusErrorText = 'Error: '.concat(statusMessage);

				// resetting the status code so user can submit again from scratch
				setStatusCode(-1);
				break;
			default:
			// default block statement;
		}

		setStatusMessageGettingData(statusGettingDataText);
		setStatusMessageUploadingData(statusUploadingDataText);
		setStatusMessageComplete(statusCompleteText);
		setStatusMessageError(statusErrorText);

		// eslint-disable-next-line no-console
		console.log(statusToDisplay);
	}

	const buttonResetClicked = () => {
		setSelectedStudentChronGrades([grades[0]]);
		setSelectedStudentAssessGrades([grades[0]]);
		setSelectedItemGrades([grades[0]]);
		setSelectedSubj(initObj);
		setSelectedProd(products[1]);
		setStartDate(today);
		setEndDate(tomorrow);
		setTimeTaken('15');
		setSelectedItemTypes([catItemTypes[0]]);
		setSelectedOpStatus([status[0], status[2]]);
		setSelectedDelimiter(delimiters[1]);
		setMinItemCount('100');
	};

	const GetStudentAssessGrades = () => {
		if (selectStudentAssessGrades.length === 0) {
			return '';
		}
		if (selectStudentAssessGrades[0].value === 'all') {
			return 'all';
			// eslint-disable-next-line no-else-return
		} else {
			return Array.prototype.map
				.call(selectStudentAssessGrades, (item) => {
					if (item.value === 'K') {
						return '0';
						// eslint-disable-next-line no-else-return
					} else {
						return item.value;
					}
				})
				.join(',');
		}
	};

	const GetStudentChronGrades = () => {
		if (selectStudentChronGrades.length === 0) {
			return '';
		}
		if (selectStudentChronGrades[0].value === 'all') {
			return 'all';
			// eslint-disable-next-line no-else-return
		} else {
			return Array.prototype.map
				.call(selectStudentChronGrades, (item) => {
					if (item.value === 'K') {
						return ['K', '0'].join();
						// eslint-disable-next-line no-else-return
					} else {
						return item.value;
					}
				})
				.join(',');
		}
	};

	const GetItemGrades = () => {
		if (selectItemGrades.length === 0) {
			return '';
		}
		if (selectItemGrades[0].value === 'all') {
			return 'all';
			// eslint-disable-next-line no-else-return
		} else {
			return Array.prototype.map
				.call(selectItemGrades, (item) => {
					if (item.value === 'K') {
						return ['K', '0'].join();
						// eslint-disable-next-line no-else-return
					} else {
						return item.value;
					}
				})
				.join(',');
		}
	};

	const GetItemTypes = () => {
		let iTypes = [{ label: '', value: '' }];
		if (selectedProd.label === 'CAT') {
			iTypes = catItemTypes;
		} else if (selectedProd.label === 'Galileo') {
			iTypes = galileoItemTypes;
		}

		if (selectItemTypes[0].value === 'all') {
			return Array.prototype.map
				.call(iTypes, (item) => {
					return item.value;
				})
				.slice(1, iTypes.length)
				.join(',');
			// eslint-disable-next-line no-else-return
		} else {
			return Array.prototype.map
				.call(selectItemTypes, (item) => {
					return item.label;
				})
				.join(',');
		}
	};

	const GetStatuses = () => {
		return Array.prototype.map
			.call(selectOpStatus, (item) => {
				return item.label;
			})
			.join(',');
	};

	const GetResponseTypes = () => {
		return Array.prototype.map
			.call(selectResponseTypes, (item) => {
				return item.label;
			})
			.join(',');
	};

	// Student grade multi-picker state
	const [selectStudentChronGrades, setSelectedStudentChronGrades] = useState<DropObject[]>([
		grades[0],
	]);
	const handleStudentChronGradeChange = (obj: OptionsType<DropObject>) => {
		let selectedValues = [...obj];

		const isAllGrades = (dropObj: DropObject) =>
			dropObj.label === 'All Grades' && dropObj.value === 'all';

		// If the user selects "All Grades" and there are already other selections, just select "All Grades"
		if (
			selectStudentChronGrades.findIndex(isAllGrades) === -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues = [grades[0]];
		}

		// If the user has "All Grades" selected and selects another grade, clear "All Grades"
		if (
			selectStudentChronGrades.findIndex(isAllGrades) !== -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues.splice(selectedValues.findIndex(isAllGrades), 1);
		}

		setSelectedStudentChronGrades(selectedValues);
	};

	// Student Assess grade multi-picker state
	// const initObjArray: DropObject[] = [];
	const [selectStudentAssessGrades, setSelectedStudentAssessGrades] = useState<DropObject[]>([
		grades[0],
	]);
	const handleStudentAssessGradeChange = (obj: OptionsType<DropObject>) => {
		let selectedValues = [...obj];

		const isAllGrades = (dropObj: DropObject) =>
			dropObj.label === 'All Grades' && dropObj.value === 'all';

		// If the user selects "All Grades" and there are already other selections, just select "All Grades"
		if (
			selectStudentAssessGrades.findIndex(isAllGrades) === -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues = [grades[0]];
		}

		// If the user has "All Grades" selected and selects another grade, clear "All Grades"
		if (
			selectStudentAssessGrades.findIndex(isAllGrades) !== -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues.splice(selectedValues.findIndex(isAllGrades), 1);
		}

		setSelectedStudentAssessGrades(selectedValues);
	};

	// Item grade multi-picker state
	const [selectItemGrades, setSelectedItemGrades] = useState<DropObject[]>([grades[0]]);
	const handleItemGradeChange = (obj: OptionsType<DropObject>) => {
		let selectedValues = [...obj];

		const isAllGrades = (dropObj: DropObject) =>
			dropObj.label === 'All Grades' && dropObj.value === 'all';

		// If the user selects "All Grades" and there are already other selections, just select "All Grades"
		if (
			selectItemGrades.findIndex(isAllGrades) === -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues = [grades[0]];
		}

		// If the user has "All Grades" selected and selects another grade, clear "All Grades"
		if (
			selectItemGrades.findIndex(isAllGrades) !== -1 &&
			selectedValues.findIndex(isAllGrades) !== -1
		) {
			selectedValues.splice(selectedValues.findIndex(isAllGrades), 1);
		}

		setSelectedItemGrades(selectedValues);
	};

	// Subject dropdown state
	const initObj: DropObject = { value: '', label: '' };
	const [selectedSubj, setSelectedSubj] = useState(initObj);
	const handleSubjectChange = (obj: DropObject | null, actionMeta: ActionMeta<DropObject>) => {
		setSelectedSubj(obj ?? initObj);
	};

	// Product dropdown state
	const [selectedProd, setSelectedProd] = useState(products[1]);
	const [selectedItemTypeSet, setSelectedItemTypeSet] = useState(catItemTypes);
	const handleProductChange = (obj: DropObject | null, actionMeta: ActionMeta<DropObject>) => {
		setSelectedProd(obj ?? initObj);
		if (obj !== null) {
			if (obj.label === 'CAT') {
				setSelectedItemTypeSet(catItemTypes);
			} else if (obj.label === 'Galileo') {
				setSelectedItemTypeSet(galileoItemTypes);
			} else {
				// make sure to remove the duplicate 'All Item Types' option
				setSelectedItemTypeSet(
					catItemTypes.concat(galileoItemTypes.slice(1, galileoItemTypes.length))
				);
			}
		}
	};

	// Delimiter dropdown state
	const [selectedDelimiter, setSelectedDelimiter] = useState(delimiters[1]);
	const handleDelimiterChange = (obj: DropObject | null, actionMeta: ActionMeta<DropObject>) => {
		setSelectedDelimiter(obj ?? initObj);
	};

	// Calendar picker state
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	const [startDate, setStartDate] = useState(new Date(today));
	const handleFromDateChange = (date: Date) => {
		setStartDate(date);
	};
	const [endDate, setEndDate] = useState(new Date(tomorrow));
	const handleToDateChange = (date: Date) => {
		setEndDate(date);
	};

	// Exclude students by time text input state
	const [timeTaken, setTimeTaken] = useState('15');
	const handleChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
		setTimeTaken(event.target.value);
	};

	// Item type multi-picker state
	const [selectItemTypes, setSelectedItemTypes] = useState<DropObject[]>([catItemTypes[0]]);
	const handleItemTypeChange = (obj: OptionsType<DropObject>) => {
		let selectedValues = [...obj];

		const isAllItemTypes = (dropObj: DropObject) =>
			dropObj.label === 'All Item Types' && dropObj.value === 'all';

		// If the user selects "All Grades" and there are already other selections, just select "All Grades"
		if (
			selectItemTypes.findIndex(isAllItemTypes) === -1 &&
			selectedValues.findIndex(isAllItemTypes) !== -1
		) {
			if (selectedProd.label === 'CAT') {
				selectedValues = [catItemTypes[0]];
			} else if (selectedProd.label === 'Galileo') {
				selectedValues = [galileoItemTypes[0]];
			}
		}

		// If the user has "All Grades" selected and selects another grade, clear "All Grades"
		if (
			selectItemTypes.findIndex(isAllItemTypes) !== -1 &&
			selectedValues.findIndex(isAllItemTypes) !== -1
		) {
			selectedValues.splice(selectedValues.findIndex(isAllItemTypes), 1);
		}

		setSelectedItemTypes(selectedValues);
	};

	// Operational status multi-picker state
	const [selectOpStatus, setSelectedOpStatus] = useState<DropObject[]>([status[0], status[1]]);
	const handleOpStatusChange = (obj: OptionsType<DropObject>) => {
		setSelectedOpStatus([...obj]);
	};

	// Minimum item count input state
	const [minItemCount, setMinItemCount] = useState('100');
	const handleChangeItemCount = (event: ChangeEvent<HTMLInputElement>) => {
		setMinItemCount(event.target.value);
	};

	// Response type multi-picker state
	const [selectResponseTypes, setSelectedResponseTypes] = useState<DropObject[]>([
		responseTypes[0],
	]);
	const handleResponseTypeChange = (obj: OptionsType<DropObject>) => {
		setSelectedResponseTypes([...obj]);
	};

	// modal stuff
	const [showModal, setShowModal] = useState(false);
	function handleProgressClose() {
		setShowModal(false);
	}
	const [statusCode, setStatusCode] = useState(-1);
	const [statusMessageGettingData, setStatusMessageGettingData] = useState('');
	const [statusMessageUploadingData, setStatusMessageUploadingData] = useState('');
	const [statusMessageComplete, setStatusMessageComplete] = useState('');
	const [statusMessageError, setStatusMessageError] = useState('');

	return (
		<div className="flex bg-white">
			{/* sidebar */}
			<Sidebar mainView={'Input'} />

			{/* content */}
			<div className="flex-grow">
				{showModal ? (
					<div className="text-center">
						<ProgressStepBar
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleProgressClose}
						/>
					</div>
				) : null}

				{/* border border-black border-solid */}
				<header className="pl-10 mt-10 text-2xl font-bold text-gray-700">Input</header>
				<div className="grid ml-10 mr-10 sm:gap-6 xl:gap-12 sm:grid-cols-1 xl:grid-cols-3">
					<div>
						{' '}
						{/* border border-black border-solid */}
						<div className="mt-3">Choose student chronological grade(s)</div>
						<MultiDropdown
							id={'ddlStudentChronGrade'}
							drops={grades}
							selected={selectStudentChronGrades}
							placeHolder={'Choose Student Chron Grade'}
							onSelectChange={handleStudentChronGradeChange}
						/>
						<div className="mt-3">Choose student assessed grade(s)</div>
						<MultiDropdown
							id={'ddlStudentAssessGrade'}
							drops={grades}
							selected={selectStudentAssessGrades}
							placeHolder={'Choose Student Grade'}
							onSelectChange={handleStudentAssessGradeChange}
						/>
						<div className="mt-3">Choose item grade(s)</div>
						<MultiDropdown
							id={'ddlItemGrade'}
							drops={grades}
							selected={selectItemGrades}
							placeHolder={'Choose Item Grade'}
							onSelectChange={handleItemGradeChange}
						/>
						<div className="mt-3">Choose date range</div>
						<Calendar
							id={'calDates'}
							fromDate={startDate.toLocaleDateString()}
							toDate={endDate.toLocaleDateString()}
							onFromChange={handleFromDateChange}
							onToChange={handleToDateChange}
						/>
					</div>
					<div>
						{' '}
						{/* border border-black border-solid */}
						<div className="mt-3">Choose a subject</div>
						<Dropdown
							id={'ddlSubjects'}
							drops={subjects}
							selected={selectedSubj}
							placeHolder={'Content Area/Subject'}
							onSelectChange={handleSubjectChange}
						/>
						<div className="mt-3">Choose a product</div>
						<Dropdown
							id={'ddlProducts'}
							drops={products}
							selected={selectedProd}
							placeHolder={'Product'}
							onSelectChange={handleProductChange}
						/>
						<div className="mt-3">Choose item type(s)</div>
						<MultiDropdown
							id={'ddlItemTypes'}
							drops={selectedItemTypeSet}
							selected={selectItemTypes}
							placeHolder={'Choose Item Types'}
							onSelectChange={handleItemTypeChange}
						/>
						<div className="mt-3">Choose item status(es)</div>
						<MultiDropdown
							id={'ddlOpStatus'}
							drops={status}
							selected={selectOpStatus}
							placeHolder={'Choose Operation Status'}
							onSelectChange={handleOpStatusChange}
						/>
					</div>
					<div>
						{' '}
						{/* border border-black border-solid */}
						<div className="mt-3">Choose assessment status(es)</div>
						<MultiDropdown
							id={'ddlResponseTypes'}
							drops={responseTypes}
							selected={selectResponseTypes}
							placeHolder={'Choose assessment status(es)'}
							onSelectChange={handleResponseTypeChange}
						/>
						<div className="mt-3">Choose points file delimiter</div>
						<div className="w-1/2">
							<Dropdown
								id={'ddlDelimiter'}
								drops={delimiters}
								selected={selectedDelimiter}
								placeHolder={'Delimiter'}
								onSelectChange={handleDelimiterChange}
							/>
						</div>
						<div className="mt-3">Choose minimum time on test</div>
						<div className="mt-1">
							<LabelInputLabel
								label1={'Exclude students who took less than '}
								label2={'minutes'}
								inputID={'txtTime'}
								time={timeTaken}
								onInputChange={handleChangeTime}
							/>
						</div>
						<div className="inline-block mt-5">
							Set the minimum item exposure
							<div className="mt-1">
								<TextInput
									id={'txtMinCount'}
									text={minItemCount}
									onInputChange={handleChangeItemCount}
									size={55}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="float-right pr-10 mt-3 mb-12">
					<button
						className="inline-block mr-3 text-center w-28 btnStyle"
						id="btnGetData"
						onClick={buttonDataClicked}
					>
						Get Data
					</button>
					<button
						className="inline-block ml-3 text-center w-28 btnStyle"
						id="btnReset"
						onClick={buttonResetClicked}
					>
						Reset
					</button>
				</div>
			</div>
		</div>
	);
}

export default Input;
