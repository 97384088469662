import React, { ChangeEvent, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken } from '@okta/okta-auth-js';
import { ActionMeta } from 'react-select';
import Swal from 'sweetalert2';
import ProgressStepBarOutput from '../../components/ProgressStepBarOutput/ProgressStepBarOutput';
import getEnv from '../../envs';
import Dropdown, { DropObject } from '../../components/Dropdown/Dropdown';
import Sidebar from '../../components/Sidebar/Sidebar';
import EditableDropdown from '../../components/EditableDropdown/EditableDropdown';

function Output() {
	const { authState, oktaAuth } = useOktaAuth();
	const [userName, setUserInfo] = useState('');
	const [eMail, setEmail] = useState('');
	const [accessToken, setAccessToken] = useState<AccessToken | undefined>(undefined);

	useEffect(() => {
		if (authState === null || !authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setAccessToken(undefined);
			setUserInfo('');
		} else {
			setAccessToken(authState.accessToken);
			oktaAuth.token.getUserInfo().then((user) => {
				const username = user ? user.given_name : '';
				setUserInfo(username ?? '');
			});
			oktaAuth.token.getUserInfo().then((em) => {
				const email = em ? em.email : '';
				setEmail(email ?? '');
			});
		}
	}, [accessToken, authState, oktaAuth]); // eslint-disable-line react-hooks/exhaustive-deps
	// Update if authState changes

	// Calls this function once when the component loads
	useEffect(() => {
		getCalibrationSetList();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getToken = () => {
		let token = '';
		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				token = `Bearer ${authState.accessToken.accessToken}`;
			}
		}

		return token;
	};

	let statusInterval: NodeJS.Timeout;
	let statusGettingDataText: string = '';
	let statusUploadingDataText: string = '';
	let statusCompleteText: string = '';
	let statusErrorText: string = '';

	// Sets up all of the various options for the dropdowns
	const irtScales = [
		{ label: 'Galileo_2005', value: '0' },
		{ label: 'Galileo_2021', value: '1' },
		{ label: 'IL_CAT_2020', value: '2' },
		{ label: 'SLA_2021', value: '3' },
	];

	// IRT Scale dropdown state
	const initObj: DropObject = { value: '', label: '' };
	const [selectedIrtScale, setSelectedIrtScale] = useState(initObj);
	const handleIrtScaleChange = (obj: DropObject | null, actionMeta: ActionMeta<DropObject>) => {
		setSelectedIrtScale(obj ?? initObj);
	};

	// Calibration set list loading state
	const [listLoading, setListLoading] = useState(true);

	// Calibration set list state
	const initList: DropObject[] = [initObj];
	const [currentCalibSets, setCalibSets] = useState(initList); // calibrationSets);

	const createOption = (label: string) => ({
		label,
		value: label,
	});

	// Calibration Set dropdown state
	const [selectedCalibrationSet, setCalibrationSet] = useState(initObj);
	const handleCalibrationSetChange = (
		obj: DropObject | null,
		actionMeta: ActionMeta<DropObject>
	) => {
		setCalibrationSet(obj ?? initObj);
	};

	const handleCalibrationInputChange = (str: string) => {
		const newOption = createOption(str);
		setCalibSets([...currentCalibSets, newOption]);
		setCalibrationSet(newOption);
		setCalibrationSetList([...currentCalibSets, newOption]);
	};

	// File picker state
	const [filesSelected, setFilesSelected] = useState<string[]>([]);
	const handleChangeFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			const files = [];

			for (let i = 0; i < event.target.files.length; i += 1) {
				files.push(event.target.files[i].name.replace('C:\\fakepath\\', ''));
			}

			setFilesSelected(files);

			// enable buttons when selections have been made
			if (files.length === 0) {
				setButtonsDisabled(true);

				document.getElementById('btnExecuteOutput')?.classList.remove('btnStyle');
				document.getElementById('btnExecuteOutput')?.classList.add('btnStyleGray');
			} else {
				setButtonsDisabled(false);

				document.getElementById('btnExecuteOutput')?.classList.remove('btnStyleGray');
				document.getElementById('btnExecuteOutput')?.classList.add('btnStyle');
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'No files have been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	async function getCalibrationSetList() {
		const token = getToken();

		const getDataUrl = `${getEnv().itemCalibrationBaseURL}getCalibrationSet/`;

		if (token !== '') {
			const response = await fetch(getDataUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				const text = await response.text();
				const result = JSON.parse(text).toString();
				const newList: DropObject[] = [];
				result.split(',').forEach((setName: string) => {
					newList.push(createOption(setName));
				});
				setCalibSets(newList);
				setListLoading(false);
			} catch (e) {
				Swal.fire({
					title: 'Error!',
					text: 'An error has occurred in setting the options for the calibration sets',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	}

	const setCalibrationSetList = (calibrationSets: DropObject[]) => {
		setListLoading(true);

		const calibLabels: string[] = [];
		calibrationSets.forEach((dobj: DropObject) => {
			calibLabels.push(dobj.label);
		});

		const obj = {
			calibrationSets: calibLabels,
		};

		const token = getToken();

		const getDataUrl = `${getEnv().itemCalibrationBaseURL}setCalibrationSet/`;

		if (token !== '') {
			fetch(getDataUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
				body: JSON.stringify(obj),
			})
				.then((response) => response.text())
				.then((jsonStatus) => {
					// do a try catch here to find out if we're getting a response with json in it, or one without
					// if it doesn't have JSON, it's a server error of some kind
					try {
						const result = JSON.parse(jsonStatus);
						setListLoading(false);
						// eslint-disable-next-line no-console
						console.log(result);
					} catch (e) {
						Swal.fire({
							title: 'Error!',
							text: 'A server error has occurred in saving the new option for the calibration sets',
							icon: 'error',
							confirmButtonText: 'Ok',
						});
					}
				})
				.catch((err) => {
					Swal.fire({
						title: 'Error!',
						text: 'An error has occurred in saving the new option for the calibration sets',
						icon: 'error',
						confirmButtonText: 'Ok',
					});
				});
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	// For enabling/disabling the buttons if files have been selected
	const [buttonsDisabled, setButtonsDisabled] = useState(true);

	const buttonExecOutClicked = () => {
		let flexmirtOutputFilename = '';
		let itemMetadataFilename = '';
		let calibrationMetadataFilename = '';
		let flexmirtSSCOutputFilename = '';

		setStatusCode(-1);
		setStatusMessageGettingData('');
		setStatusMessageUploadingData('');
		setStatusMessageComplete('');
		setStatusMessageError('');

		if (filesSelected.length !== 4) {
			Swal.fire({
				title: 'Error!',
				text: 'There should be 4 files selected, the flexMIRT output, the flexMIRT SSC, the item metadata, and the calibration metadata files.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else {
			filesSelected.forEach((filename, index) => {
				if (filename.indexOf('_Itl.txt') !== -1) {
					itemMetadataFilename = filename;
				} else if (filename.indexOf('_VersionB2-prm.txt') !== -1) {
					flexmirtOutputFilename = filename;
				} else if (filename.indexOf('_Inf.txt') !== -1) {
					calibrationMetadataFilename = filename;
				} else if (filename.indexOf('_VersionB2-ssc.txt') !== -1) {
					flexmirtSSCOutputFilename = filename;
				}
			});

			// check that step 1 - one of the filenames has "_Itl.txt" and step 2 - check that the filenames are the same without the extensions and "_Itl.txt"
			if (itemMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'An item metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (flexmirtOutputFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'A flexMIRT output file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (flexmirtSSCOutputFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'A flexMIRT SSC output file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (calibrationMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'A calibration metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			const imBaseFilename = itemMetadataFilename.replace('_Itl.txt', '');
			const fmBaseFilename = flexmirtOutputFilename.replace('_VersionB2-prm.txt', '');
			const cmBaseFilename = calibrationMetadataFilename.replace('_Inf.txt', '');
			const sscBaseFilename = flexmirtSSCOutputFilename.replace('_VersionB2-ssc.txt', '');

			if (
				imBaseFilename !== fmBaseFilename ||
				imBaseFilename !== cmBaseFilename ||
				fmBaseFilename !== cmBaseFilename ||
				imBaseFilename !== sscBaseFilename ||
				cmBaseFilename !== sscBaseFilename ||
				fmBaseFilename !== sscBaseFilename
			) {
				Swal.fire({
					title: 'Error!',
					text: 'The flexMIRT output, flexMIRT SSC output, item metadata, and calibration metadata files must have the same names.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
		}

		if (selectedIrtScale.label === '') {
			Swal.fire({
				title: 'Error!',
				text: 'An IRT scale must be selected from the dropdown.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		if (selectedCalibrationSet.label === '') {
			Swal.fire({
				title: 'Error!',
				text: 'A calibration set must be selected from the dropdown.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		const obj = {
			flexmirtOutputFilename,
			itemMetadataFilename,
			calibrationMetadataFilename,
			flexmirtSSCOutputFilename,
			irtScale: selectedIrtScale.label,
			calibrationSet: selectedCalibrationSet.label,
			userName,
			eMail,
		};

		const token = getToken();

		const requestID = new Date().getTime();
		const baseUrl = getEnv().itemCalibrationBaseURL;

		const getDataUrl = `${baseUrl}executeOutput/${requestID}`;
		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus(baseUrl, token, requestID), 2000);

		if (token !== '') {
			setShowModal(true);
			fetch(getDataUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
				body: JSON.stringify(obj),
			})
				.then((response) => response.text())
				.then((jsonStatus) => {
					// do a try catch here to find out if we're getting a response with json in it, or one without
					// if it doesn't have JSON, it's a server error of some kind
					try {
						const result = JSON.parse(jsonStatus);
						setStatusCode(result.statusCode);
						WriteStatusInfo(result.statusCode, result, result.message);
					} catch (e) {
						setTimeout(() => {
							setStatusCode(99);
							// this would be a server error
							// if it has multiple lines, split it up to get the first line of it
							const statusText = () => {
								return jsonStatus?.split('\n') ? jsonStatus?.split('\n')[0] : jsonStatus;
							};
							WriteStatusInfo(99, '', statusText());
						}, 3000);
					}
				})
				.catch((err) => {
					clearInterval(statusInterval);
					setTimeout(() => {
						setStatusCode(99);
						WriteStatusInfo(99, err, err.message);
					}, 3000);
				});
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	// modal stuff
	const [showModal, setShowModal] = useState(false);
	function handleProgressClose() {
		setShowModal(false);
	}
	const [statusCode, setStatusCode] = useState(-1);
	const [statusMessageGettingData, setStatusMessageGettingData] = useState('');
	const [statusMessageUploadingData, setStatusMessageUploadingData] = useState('');
	const [statusMessageComplete, setStatusMessageComplete] = useState('');
	const [statusMessageError, setStatusMessageError] = useState('');

	async function CheckStatus(baseUrl: string, token: string, requestID: number) {
		let code = -1;
		let statusToDisplay = '';
		let statusMessage = '';

		// if the statusCode is 99, it means an error has been detected already and the API should not be called again
		if (statusCode === 99) {
			code = 99;
		} else {
			// eslint-disable-next-line prefer-template
			const getStatusUrl = baseUrl + 'outputStatus/' + requestID;

			const response = await fetch(getStatusUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				// do a try catch here to find out if we're getting a response with json in it, or one without
				// if it doesn't have JSON, it's a server error of some kind
				const text = await response.text();
				const statusData = JSON.parse(text);
				statusToDisplay = statusData;
				statusMessage = statusData.message;
				code = statusData.statusCode;
			} catch (e) {
				statusMessage = response.message;
				code = 99;
			}
			setStatusCode(code);
		}

		WriteStatusInfo(code, statusToDisplay, statusMessage);

		if (code >= 6) {
			clearInterval(statusInterval);
		}
	}

	function WriteStatusInfo(code: number, statusToDisplay: string, statusMessage: string) {
		statusErrorText = '';
		switch (code) {
			case -1:
				statusErrorText = 'No status could be found.';
				break;
			case 0:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 1:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 2:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 3:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 4:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 5:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 6:
				statusCompleteText = JSON.stringify(statusToDisplay);
				break;
			case 7:
				statusErrorText = 'No data could be found for the filter selections.';
				break;
			case 99:
				statusErrorText = 'Error: '.concat(statusMessage);

				// resetting the status code so user can submit again from scratch
				setStatusCode(-1);
				break;
			default:
			// default block statement;
		}

		setStatusMessageGettingData(statusGettingDataText);
		setStatusMessageUploadingData(statusUploadingDataText);
		setStatusMessageComplete(statusCompleteText);
		setStatusMessageError(statusErrorText);

		// eslint-disable-next-line no-console
		console.log(statusToDisplay);
	}

	return (
		<div className="flex bg-white">
			{/* sidebar */}
			<Sidebar mainView={'Output'} />

			{/* content */}
			<div className="flex-grow">
				{showModal ? (
					<div className="text-center">
						<ProgressStepBarOutput
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleProgressClose}
						/>
					</div>
				) : null}

				{/* border border-black border-solid */}
				<header className="pl-10 mt-10 text-2xl font-bold text-gray-700">Output</header>
				<div className="pl-10 mt-5 text-base">Select your file(s) and click to upload</div>
				<div className="pl-10 mt-5">
					<input type="file" id="filePicker" multiple onChange={handleChangeFilesSelected} />
				</div>

				<div className="grid mt-5 ml-10 mr-10 sm:gap-6 xl:gap-12 sm:grid-cols-1 xl:grid-cols-3 xl:divide-x-2">
					<div>
						{' '}
						<div className="mt-3">Choose IRT Scale</div>
						<Dropdown
							id={'ddlIrtScale'}
							drops={irtScales}
							selected={selectedIrtScale}
							placeHolder={'IRT Scale'}
							onSelectChange={handleIrtScaleChange}
						/>
						<div className="mt-3">Choose calibration set(s)</div>
						<EditableDropdown
							id={'ddlCalibrationSet'}
							drops={currentCalibSets}
							selected={selectedCalibrationSet}
							placeHolder={'Calibration Set'}
							isLoading={listLoading}
							onSelectChange={handleCalibrationSetChange}
							onInputChange={handleCalibrationInputChange}
						/>
						<button
							className="mt-5 text-center w-28 btnStyleGray"
							id="btnExecuteOutput"
							onClick={buttonExecOutClicked}
							disabled={buttonsDisabled}
						>
							Start Upload
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Output;
