import React, { ChangeEvent, useState, useEffect, Dispatch } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { ActionMeta, OptionsType } from 'react-select';
import { AccessToken } from '@okta/okta-auth-js';
import Swal from 'sweetalert2';
import getEnv from '../../envs';
import { DropObject } from '../../components/Dropdown/Dropdown';
import MultiDropdown from '../../components/MultiDropdown/MultiDropdown';
import Sidebar from '../../components/Sidebar/Sidebar';
import EditableDropdown from '../../components/EditableDropdown/EditableDropdown';
import { delayedChanges, immediateChanges } from '../../constants/data';
import TextInput from '../../components/TextInput/TextInput';
import ProgressStepBarSetCurrentPreview from '../../components/ProgressStepBarSetCurrentPreview/ProgressStepBarSetCurrentPreview';
import ProgressStepBarSetCurrentUpdate from '../../components/ProgressStepBarSetCurrentUpdate/ProgressStepBarSetCurrentUpdate';
import ModalSetCurrent from '../../components/ModalSetCurrent/ModalSetCurrent';

function SetCurrent() {
	const { authState, oktaAuth } = useOktaAuth();
	const [accessToken, setAccessToken] = useState<AccessToken | undefined>(undefined);

	useEffect(() => {
		if (authState === null || !authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setAccessToken(undefined);
		} else {
			setAccessToken(authState.accessToken);
		}
	}, [accessToken, authState, oktaAuth]); // eslint-disable-line react-hooks/exhaustive-deps
	// Update if authState changes

	// Calls this function once when the component loads
	useEffect(() => {
		getList(
			'getSnowflakeCalibrationSet/',
			setCurrentCalibSets,
			setCalibListLoading,
			'calibration set'
		);
		getList('getSnowflakeIRTScale/', setIrtScalesSets, setIrtScaleListLoading, 'irt scale');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getToken = () => {
		let token = '';
		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				token = `Bearer ${authState.accessToken.accessToken}`;
			}
		}

		return token;
	};

	// Subject dropdown state
	const initObj: DropObject = { value: '', label: '' };

	// handle file name change
	const [filesName, setFileName] = useState('');
	const handleChangeFileName = (event: ChangeEvent<HTMLInputElement>) => {
		setFileName(event.target.value);
	};

	// gets list from snowflake and populates drop down objects with the list
	async function getList(
		endOfURL: string,
		setItemSets: Dispatch<DropObject[]>,
		setListLoad: Dispatch<boolean>,
		item: string
	) {
		const token = getToken();
		const getDataUrl = getEnv().itemCalibrationBaseURL + endOfURL;

		if (token !== '') {
			const response = await fetch(getDataUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				const text = await response.text();
				const result = JSON.parse(text).toString();
				const newList: DropObject[] = [];
				result.split(',').forEach((setName: string) => {
					newList.push(createOption(setName));
				});
				if (endOfURL.includes('Calib')) {
					setCurrentCalibSets(newList);
					setCalibListLoading(false);
				} else {
					setIrtScalesSets(newList);
					setIrtScaleListLoading(false);
				}
			} catch (e) {
				Swal.fire({
					title: 'Error!',
					text: 'An error has occurred in setting the options for the calibration sets',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	}

	// Calibration set list loading state
	const [calibListLoading, setCalibListLoading] = useState(true);

	// Calibration set list state
	const initList: DropObject[] = [initObj];
	const [currentCalibSets, setCurrentCalibSets] = useState(initList); // calibrationSets);

	const createOption = (label: string) => ({
		label,
		value: label,
	});

	// Calibration Set dropdown state
	const [selectedCalibrationSet, setCalibrationSet] = useState(initObj);
	const handleCalibrationSetChange = (
		obj: DropObject | null,
		actionMeta: ActionMeta<DropObject>
	) => {
		setCalibrationSet(obj ?? initObj);
	};

	const handleCalibrationInputChange = (str: string) => {
		const newOption = createOption(str);
		setCurrentCalibSets([...currentCalibSets, newOption]);
		// use state obj
		setCalibrationSet(newOption);
		// setCalibrationSetList([...currentCalibSets, newOption]);
		setList([...currentCalibSets, newOption]);
	};

	// IRT multi-picker state
	const [, setIrtScaleListLoading] = useState(true);

	// IRT list state
	const [currentIrtScalesSets, setIrtScalesSets] = useState(initList);

	// IRT dropdown state
	const [selectedIrtScales, setIrtScales] = useState<DropObject[]>([]);
	const handleIrtScalesChange = (obj: OptionsType<DropObject>) => {
		const selectedValues = [...obj];
		setIrtScales(selectedValues);
		setList(currentIrtScalesSets);
	};

	const setList = (sets: DropObject[]) => {
		const labels: string[] = [];
		sets.forEach((dobj: DropObject) => {
			labels.push(dobj.label);
		});
	};

	// status info for ProgressStepBarSetCurrent
	let statusInterval: NodeJS.Timeout;
	let statusGettingDataText: string = '';
	let statusUploadingDataText: string = '';
	let statusCompleteText: string = '';
	let statusErrorText: string = '';

	const buttonPreviewClicked = () => {
		const irtScale = GetItems(selectedIrtScales);

		if (selectedCalibrationSet.label === '' || filesName === '' || irtScale === '') {
			Swal.fire({
				title: 'Error!',
				text: 'All necessary settings have not been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		if (filesName.length < 10) {
			Swal.fire({
				title: 'Error!',
				text: 'File name must be 10 characters or longer.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		// Verify that file is itl
		if (!fileSelected || !fileSelected.includes('_Itl.txt')) {
			Swal.fire({
				title: 'Error!',
				text: 'The file uploaded must be an Itl.txt file.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		const obj = {
			calibrationSet: selectedCalibrationSet.label,
			fileName: filesName,
			irtScales: irtScale,
			itlFile: fileSelected,
		};

		let token = '';

		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				token = `Bearer  ${authState.accessToken.accessToken}`;
			}
		}

		const env = getEnv();
		const requestID = new Date().getTime();

		const baseUrl = env.itemCalibrationBaseURL;
		const getDataUrl = `${baseUrl}retrievePreviewData/${requestID}`;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus(baseUrl, token, requestID, true), 2000);

		if (token !== '') {
			// start showing the progress on the Step bar
			setShowModal(true);
			fetchResults(getDataUrl, token, obj);
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	const GetItems = (selectedItems: DropObject[]) => {
		// it is possible for the user to unselect everything
		// this will allow us to handle this case and nothing crashes
		if (selectedItems.length === 0) {
			return '';
		}

		return Array.prototype.map
			.call(selectedItems, (item) => {
				return item.label;
			})
			.join(',');
	};

	async function CheckStatus(baseUrl: string, token: string, requestID: number, preview: boolean) {
		let code = -1;
		let statusToDisplay = '';
		let statusMessage = '';

		// if the statusCode is 99, it means an error has been detected already and the API should not be called again
		if (statusCode === 99) {
			code = 99;
		} else {
			const getStatusUrl = `${baseUrl}inputStatus/${requestID}`;

			const response = await fetch(getStatusUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				// do a try catch here to find out if we're getting a response with json in it, or one without
				// if it doesn't have JSON, it's a server error of some kind
				const text = await response.text();
				const statusData = JSON.parse(text);
				// eslint-disable-next-line no-console
				console.log(statusData);
				statusToDisplay = statusData;
				statusMessage = statusData.message;
				code = statusData.statusCode;
			} catch (e) {
				statusMessage = response.message;
				code = 99;
			}
			setStatusCode(code);
		}

		WriteStatusInfo(code, statusToDisplay, statusMessage);

		if (code >= 6) {
			clearInterval(statusInterval);
		}
	}

	function WriteStatusInfo(code: number, statusToDisplay: string, statusMessage: string) {
		statusErrorText = '';
		switch (code) {
			case -1:
				statusErrorText = 'No status could be found.';
				break;
			case 0:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 1:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 4:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 5:
				statusCompleteText = JSON.stringify(statusToDisplay);
				break;
			case 6:
				statusCompleteText = JSON.stringify(statusToDisplay);
				break;
			case 7:
				statusErrorText = 'No data could be found for the filter selections.';
				break;
			case 99:
				statusErrorText = 'Error: '.concat(statusMessage);
				// reset the status code for when the user restarts the process
				setStatusCode(-1);
				break;
			default:
				break;
		}

		setStatusMessageGettingData(statusGettingDataText);
		setStatusMessageUploadingData(statusUploadingDataText);
		setStatusMessageComplete(statusCompleteText);
		setStatusMessageError(statusErrorText);
	}

	// modal info for Preview
	const [showModal, setShowModal] = useState(false);
	function handleProgressClose() {
		setShowModal(false);
	}
	const [statusCode, setStatusCode] = useState(-1);
	const [statusMessageGettingData, setStatusMessageGettingData] = useState('');
	const [statusMessageUploadingData, setStatusMessageUploadingData] = useState('');
	const [statusMessageComplete, setStatusMessageComplete] = useState('');
	const [statusMessageError, setStatusMessageError] = useState('');

	// update set current modal info
	const [showUpdateModal, setShowUpdateModal] = useState(false);

	// process for update set current model
	const [showUpdateProgressModal, setShowUpdateProgressModal] = useState(false);
	function handleUpdateProgressClose() {
		setShowUpdateProgressModal(false);
	}

	const buttonUpdatesClicked = () => {
		const irtScales = GetItems(selectedIrtScales);

		if (selectedCalibrationSet.label === '' || irtScales === '') {
			Swal.fire({
				title: 'Error!',
				text: 'All necessary settings have not been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}
		// Verify that file is itl
		if (!fileSelected || !fileSelected.includes('_Itl.txt')) {
			Swal.fire({
				title: 'Error!',
				text: 'The file uploaded must be an Itl.txt file.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}
		// eslint-disable-next-line no-console
		console.log(fileSelected);
		setShowUpdateModal(true);
	};

	function handleUpdateClose() {
		setShowUpdateModal(false);
		setTypeOfUpdate(delayedChanges);
	}

	const [typeOfUpdate, setTypeOfUpdate] = useState(delayedChanges);

	function onSubmitChanges() {
		setShowUpdateModal(false);

		const selectedIrtScale = GetItems(selectedIrtScales);

		let token = '';

		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				token = `Bearer  ${authState.accessToken.accessToken}`;
			}
		}

		const env = getEnv();
		const requestID = new Date().getTime();

		const baseUrl = env.itemCalibrationBaseURL;

		if (token !== '') {
			// we want to start a status check on a 1 sec interval
			// for some reason, a 2 second interval does not always track the data we need
			// and leaves the criteria blank in the modal displaying the progress
			statusInterval = setInterval(() => CheckStatus(baseUrl, token, requestID, false), 1000);
			setShowUpdateProgressModal(true);

			if (typeOfUpdate === immediateChanges) {
				const getDataUrl = `${baseUrl}updateSetCurrent/${requestID}`;

				const obj = {
					calibrationSet: selectedCalibrationSet.label,
					irtScales: selectedIrtScale,
					itlFile: fileSelected,
				};
				fetchResults(getDataUrl, token, obj);
			} else {
				const getDataUrl = `${baseUrl}delayedUpdateSetCurrent/${requestID}`;
				const obj = {
					calibrationSet: selectedCalibrationSet.label,
					date: updateDate.toDateString(),
					irtScales: selectedIrtScale,
					itlFile: fileSelected,
				};
				fetchResults(getDataUrl, token, obj);
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
		// resets the button in the modal after the process has been formed
		setTypeOfUpdate(delayedChanges);
	}

	function fetchResults(getDataUrl: string, token: string, obj: Record<string, unknown>) {
		fetch(getDataUrl, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				authorization: token,
			},
			body: JSON.stringify(obj),
		})
			.then((response) => response.text())
			.then((jsonStatus) => {
				// do a try catch here to find out if we're getting a response with json in it, or one without
				// if it doesn't have JSON, it's a server error of some kind
				try {
					const result = JSON.parse(jsonStatus);
					setStatusCode(result.StatusCode);
					WriteStatusInfo(result.StatusCode, result, result.message);
				} catch (e) {
					setTimeout(() => {
						// this would be a server error
						// if it has multiple lines, split it up to get the first line of it
						const statusText = () => {
							return jsonStatus?.split('\n') ? jsonStatus?.split('\n')[0] : jsonStatus;
						};
						WriteStatusInfo(99, '', statusText());
					}, 3000);
				}
			})
			.catch((err) => {
				clearInterval(statusInterval);
				setTimeout(() => {
					setStatusCode(99);
					WriteStatusInfo(99, err, err.message);
				}, 3000);
			});
	}

	// Calendar state
	// we want only want to schedule in the future so start off with tomorrow
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const [updateDate, setUpdateDate] = useState(tomorrow);

	// File picker state
	const [fileSelected, setFileSelected] = useState<string>();
	const handleChangeFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			let file = '';
			if (event.target.files.length > 0) {
				file = event.target.files[0].name.replace('C:\\fakepath\\', '');
				// eslint-disable-next-line no-console
				console.log(file);
			}
			setFileSelected(file);

			// enable buttons when selections have been made
			if (file === '') {
				setButtonsDisabled(true);

				document.getElementById('btnUpdatesData')?.classList.remove('btnStyle');
				document.getElementById('btnUpdatesData')?.classList.add('btnStyleGray');
				document.getElementById('btnPreviewData')?.classList.remove('btnStyle');
				document.getElementById('btnPreviewData')?.classList.add('btnStyleGray');
			} else {
				setButtonsDisabled(false);

				document.getElementById('btnUpdatesData')?.classList.remove('btnStyleGray');
				document.getElementById('btnUpdatesData')?.classList.add('btnStyle');
				document.getElementById('btnPreviewData')?.classList.remove('btnStyleGray');
				document.getElementById('btnPreviewData')?.classList.add('btnStyle');
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'No files have been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	// If no files have been selected, then the user shouldn't be able to continue
	const [buttonsDisabled, setButtonsDisabled] = useState(true);

	return (
		<div className="flex bg-white">
			{/* sidebar */}
			<Sidebar mainView={'SetCurrent'} />
			{/* content */}
			<div className="flex-grow">
				{showModal ? (
					<div className="text-center">
						<ProgressStepBarSetCurrentPreview
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleProgressClose}
						/>
					</div>
				) : null}
				{showUpdateModal ? (
					<div className="text-center">
						<ModalSetCurrent
							updateDate={updateDate}
							setUpdateDate={setUpdateDate}
							typeOfUpdate={typeOfUpdate}
							setTypeOfUpdate={setTypeOfUpdate}
							onSubmit={onSubmitChanges}
							onModalClose={handleUpdateClose}
						/>
					</div>
				) : null}
				{showUpdateProgressModal ? (
					<div className="text-center">
						<ProgressStepBarSetCurrentUpdate
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleUpdateProgressClose}
						/>
					</div>
				) : null}
				{/* border border-black border-solid */}
				<header className="pl-10 mt-10 text-2xl font-bold text-gray-700">Set Current</header>
				<div className="pl-10 mt-5 text-base">
					Select your parameters and either preview the records to be changed or submit the changes.
				</div>
				<div className="grid ml-10">
					<div className="grid">
						{/* border border-black border-solid */}
						<div className="inline-block mt-3 xs:grid-cols-2">
							<div className="mt-1">
								Set name for Preview File
								<TextInput
									id={'txtFileName'}
									text={filesName}
									onInputChange={handleChangeFileName}
									size={350}
								/>
								10 character minimum
							</div>
							<div className="inline-block mt-5 xs:grid-cols-6">
								Select your itl file and click to upload
							</div>
							<div className="mt-1">
								<input type="file" id="filePicker" onChange={handleChangeFilesSelected} />
							</div>
						</div>
					</div>
					<div className="grid" style={{ width: '350px' }}>
						<div className="inline-block mt-3 xs:grid-cols-2">
							<div className="mt-5">Choose IRT Scale(s)</div>
							<MultiDropdown
								id={'ddlIrtScales'}
								drops={currentIrtScalesSets}
								selected={selectedIrtScales}
								placeHolder={'Choose IRT Scale'}
								onSelectChange={handleIrtScalesChange}
							/>
							{/* border border-black border-solid */}
							<div className="mt-5">Choose calibration set(s)</div>
							<EditableDropdown
								id={'ddlCalibrationSet'}
								drops={currentCalibSets}
								selected={selectedCalibrationSet}
								placeHolder={'Calibration Set'}
								isLoading={calibListLoading}
								onSelectChange={handleCalibrationSetChange}
								onInputChange={handleCalibrationInputChange}
							/>
						</div>
					</div>
					<div className="pr-10 mt-5">
						<button
							className="mr-3 text-center w-28 btnStyleGray"
							id="btnPreviewData"
							onClick={buttonPreviewClicked}
							disabled={buttonsDisabled}
						>
							Preview Records
						</button>
						<button
							className="text-center w-28 btnStyleGray"
							id="btnUpdatesData"
							onClick={buttonUpdatesClicked}
							disabled={buttonsDisabled}
						>
							Submit Updates
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SetCurrent;
